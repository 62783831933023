<template>
  <div class="coianter">
    <el-tabs v-model="activeName">
      <el-tab-pane label="个人资料" name="first">
        <exportuser />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>
<script>
import exportuser from "./exportuser.vue";
import qiyeuser from "./qiyeuser.vue";
export default {
  components: { exportuser, qiyeuser },
  data() {
    return {
      activeName: "first",
    };
  },
  methods: {
    goback() {
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped>
.coianter {
  background-color: #ffffff;
  padding: 27px 37px;
  margin-top: 40px;
  margin-right: 20px;
  margin-left: 20px;
}
</style>
